import "./App.css";
import Payment from "./Payment";
import Completion from "./Completion";
import Pricing from "./Pricing";
import  Login  from "./Login";
import Signup from "./Signup"
import SportApp from "./sportsc/SportApp";
import { Routes, Route , useNavigate, NavLink,Link} from "react-router-dom";
import { useContext } from 'react';
import { PriceContext } from './context';
import { AwsConfigAuth } from "./config/auth";
import {Amplify} from "aws-amplify";
import React, { useState, useEffect,Fragment } from "react";
import styled from "styled-components";
import { logout } from './authenticate';


import Home from './containers/Home'

function App() {
  const { price, setPrice ,isAuthenticated,userHasAuthenticated} = useContext(PriceContext);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 395){setScreenWidth(true)}
      else{setScreenWidth(false)}
    };
  
    window.addEventListener('resize', handleResize);

    // Limpia el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAuthenticated]);

  console.log(screenWidth)
  const Nav = styled.nav`
  position: fixed;
  width: 100vw;
  box-shadow: 0 0 10px $clr-gray300;
  @media (min-width: 768px) {
  
  }

`;

const NavList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin: 0 2rem;
`;

const NavLogo = styled.li`
  list-style: none;
  margin-right: auto;
  cursor: pointer;

  svg {
    width: 2.5rem;
    transition: fill 250ms ease-in;

    &:hover,
    &:focus {
      fill: $clr-primary;
    }
  }
`;

const NavListItemDrop = styled.ul`
  display: ${props => (props.isOpen ? 'flex' : 'none')}; /* Show/hide based on state */
  flex-direction: column; /* Organiza los elementos en una columna */
  justify-content: space-around; /* Espacia uniformemente los elementos */
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  list-style: none;
  height: 10vh;
  right: 1; /* Alinea el menú desplegable con el borde derecho */
  transform: translateX(-50%); /* Ajusta el valor según sea necesario para moverlo a la izquierda */
  color:black;
  li {
    padding: 0.5rem 0;

    color:black;
  }

  a{
    color: black;
    &:hover {
      color: purple;
    }

  }
`;
const NavListItem = styled.li`
  list-style:none;

  position: relative;
  cursor: pointer;
`;
  const NavbarContainer = styled.nav`
  display: flex;
  width:${props => (props.screenWidth ? '80vw' : '100vw')};
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;

  a{
    color: ${props => (props.screenWidth ? 'white' : 'black')};
    justify-self:space-between;
    &:hover {
      color: #ADD8E6;
    }
  }
  .brand {
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
  }

  .links {
    display: flex;
    gap: 1rem;

 
    }
  }

  .toggle {
    display: none;
  }


`;

async function handleLogout(event) {
  event.preventDefault();

  try {
    console.log('logged out')
    logout(userHasAuthenticated)
  } catch (error) {
    console.log(error);
  }
}

const handleDropdownClick = () => {
  setIsDropdownOpen(!isDropdownOpen);
};

  return (
    <Fragment>
      <NavbarContainer screenWidth={screenWidth}>
            <NavLink to="/" className="brand">
              StatTracker
            </NavLink>
            <div className="toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            
            </div>
            <div className={`links ${isMenuOpen ? "active" : ""}`}>
              {isAuthenticated ? (
                <> {screenWidth ? (<>
                    <Link to="/analysis">analysis</Link>

                <Link to="/" onClick={handleLogout}>
                Logout
                  </Link>
                  
                   </>) :( <> <NavListItem  screenWidth={screenWidth}onClick={handleDropdownClick}>
                    ☰
          <NavListItemDrop isOpen={isDropdownOpen}>
                  {/* <Link to="/pricing">pricing</Link> */}
                  <Link to="/analysis">analysis</Link>

                  <Link to="/" onClick={handleLogout}>
                    Logout
                  </Link>
                  </NavListItemDrop>
        </NavListItem></>)}
                       
                </>
              ) : (
                <> {screenWidth ? (<>      <Link to="/signup">Signup</Link>
                <Link to="/login">Login</Link></>): (<>  <NavListItem onClick={handleDropdownClick}>
                              ☰
                        <NavListItemDrop isOpen={isDropdownOpen}>
                            <Link to="/login">Login</Link>
                            <Link to="/signup">Signup</Link>

                       </NavListItemDrop>
                    </NavListItem></>)}
                   
                 
                </>
              )}
            </div>
          </NavbarContainer>

      <Routes>
        <Route path="/payment" element={<Payment />} />
        <Route path="/pricing" element={<Pricing setPrice={setPrice} navigate={navigate}/>} />
        <Route path="/completion" element={<Completion />} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/analysis" element={<SportApp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<SportApp />} />

      </Routes>
    </Fragment>
  );
}

export default App;