import axios from 'axios';
import styled from 'styled-components';
import { PointsContext } from './pointContext';
import { PriceContext } from '../context';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
import { useState, Fragment, createContext, useContext, useEffect  } from 'react';
import { authenticate } from '../authenticate';

const FormContainer = styled.div`
  ${props => (props.disabled || !props.isAuthenticated) && `
    pointer-events: none;
    opacity: 0.5;
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background-color: #f8f9fa;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Message = styled.div`
  margin-top: 20px;
  color: red;
`;
  const PlayerForm = ({oldPlayer,setOld,setDates, setPoints, setAssists, setRebounds, setTeams, player, setPointsPred_l, setAssistsPred_l,setReboundsPred_l}, points)  => {
  const [pstats, setPstats] = useState('');

  const {credit, setCredit, disabled, setDisable, isAuthenticated,message, setMessage, email} = useContext(PriceContext);
  const { playerName, setPlayerName} = useContext(PointsContext);

  
    useEffect( async() => {

      await grabCredits()
     
      // Perform an action when the component mounts or when isDisabled changes
      if(!isAuthenticated){
        setMessage('Login to use')
     
      }
      else{
        if(disabled){
          setMessage('add more credits')
        }
        else{
          setMessage('')
        }
      }
    },[isAuthenticated, disabled, credit]);



    async function spendCredit() {
      try {
        axios({
          method: 'post',
          url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
         
          withCredentials: false,
          data: {
            email:email,
            path:'user-spend-credit'
          },
        }).then(function (response) {
          
        })
        .catch(function (error) {
        });
  
  
  
      } catch (error) {
          console.error('Error:', error);
      }
  }

  async function grabCredits() {
    try {
      axios({
        method: 'post',
        url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
       
        withCredentials: false,
        data: {
          email:email,
          path:'user-credit-load'
        },
      }).then(function (response) {
         const data = response.data;
        let credits_purchased = Number(data);
        setCredit(credits_purchased);
        setTimeout(() => {
            // Código a ejecutar después de 1 segundo
        }, 5000);
      })
      .catch(function (error) {
      });



    } catch (error) {
        console.error('Error:', error);
    }
}

  
  function combineColumns(row) {
    const year = String(row.year);
    const month = String(row.month).padStart(2, '0');
    const day = String(row.day).padStart(2, '0');
    const cleanDate = `${year}-${month}-${day}`;
    return cleanDate;
  }


  function orderListByDates(dates, listToOrder, order = 'asc') {
    // Combine the dates and the other list into a single array of objects
    const combined = dates.map((date, index) => ({ date, value: listToOrder[index] }));
  
    // Sort the combined array by date
    combined.sort((a, b) => {
      if (order === 'asc') {
        return a.date.localeCompare(b.date);
      } else {
        return b.date.localeCompare(a.date);
      }
    });
  
    // Extract the sorted list
    const orderedList = combined.map(item => item.value);
  
    return orderedList;
  }

  async function assists_pred(x){

    setAssistsPred_l('computing prediciton...')
    axios({
      method: 'post',
      url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
    
      withCredentials: false,
      params: {
      player:x,
        path:'assists'
      },
    }).then(function (response) {
      let res = response.data
      let numbers_res = res.match(/[\d.]+/g);
      let numbers_list = numbers_res.map(Number);
      setAssistsPred_l(numbers_list[1])


    })
    .catch(function (error) {
    });

 }


 async function rebounds_pred(x){

  setReboundsPred_l('computing prediciton...')
  axios({
    method: 'post',
    url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
  
    withCredentials: false,
    params: {
    player:x,
      path:'rebounds'
    },
  }).then(function (response) {
    let res = response.data
    let numbers_res = res.match(/[\d.]+/g);
    let numbers_list = numbers_res.map(Number);
    setReboundsPred_l(numbers_list[1])


  })
  .catch(function (error) {
  });

}

 async function points_pred(x){

    setPointsPred_l('computing prediciton...')
    axios({
      method: 'post',
      url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
    
      withCredentials: false,
      params: {
      player:x,
        path:'points'
      },
    }).then(function (response) {
      let res = response.data
      let numbers_res = res.match(/[\d.]+/g);
      let numbers_list = numbers_res.map(Number);
      setPointsPred_l(numbers_list[1])


    })
    .catch(function (error) {
    });

 }
  
  async function grab_pred(x){
      points_pred(x)
      assists_pred(x)
      rebounds_pred(x)
  }
  
  async function load_player (x) {


     await grab_pred(x)
     let player = x
     axios({
      method: 'post',
      url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
      withCredentials: false,
      data: {
      player:player,
        path:'player-data'
      },
    }).then(function (response) {
      let data = response.data
      let mdates = []
      let spoints = []
      let srebounds = []
      let sassists = []
      let steams = []
      for (let i = 0; i < data.length; i++) {
       
      
        let d = combineColumns(data[i])
        let p = data[i].points
        let t = data[i].total_rebounds
        let a = data[i].assists
        let v = data[i].opposing_team
        mdates.push(d)
        spoints.push(p)
        srebounds.push(t)
        sassists.push(a)
        steams.push(v)
      }
      let ordered_points = orderListByDates(mdates, spoints,'asc').slice(-10)
      let ordered_assists = orderListByDates(mdates, sassists,'asc').slice(-10)
      let ordered_rebounds = orderListByDates(mdates, srebounds,'asc').slice(-10)
      let ordered_teams = orderListByDates(mdates, steams,'asc').slice(-10)
      // default is asc
      mdates = mdates.sort().slice(-10)
    
      setDates(mdates)
      setPoints(ordered_points)
      setAssists(ordered_assists)
      setRebounds(ordered_rebounds)
      setTeams(ordered_teams)
  
    })
    .catch(function (error) {
    });
    



  }
  const handleSubmit = (event) => {
    event.preventDefault();


    setOld(playerName)
    if (playerName.length > 3){
      load_player(playerName); 
      }


      spendCredit()

      let  oldCredit = credit
      let newCredit = oldCredit - 1
      setCredit(newCredit)


      if(credit == 1){
        setDisable(true)

        setMessage('Grab more Credits to use');
      }
    // load_player(playerName)

  };


  return (
    <FormContainer disabled={disabled} isAuthenticated={isAuthenticated}>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          type="text"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          placeholder={oldPlayer}
          autoFocus
        />
          {/* <StyledInput
          type="text"
          value={pstats}
          onChange={(e) => setPstats(e.target.value)}
          placeholder={pstats}
          autoFocus
        /> */}
        <SubmitButton type="submit">Load Player</SubmitButton>
        {message && <Message>{message}</Message>}
      </StyledForm>
    </FormContainer>
  );
};

export default PlayerForm 