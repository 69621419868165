
import axios from 'axios';
import styled from 'styled-components';
import { PointsContext } from './pointContext';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
import { useState, Fragment, createContext, useContext  } from 'react';
const NavContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #333;
`;

const DropdownButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

const DropdownContent = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 200px;  /* Adjust this value as needed */
  overflow-y: auto;
  transition: max-height 1.3s ease-in-out;  /* Add this line for smooth transition */
`;

const ListItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
  transition: background-color 1.3s ease;  /* Add this line for smooth hover effect */
`;

const SelectionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const OpenButton = styled.button`
 display: ${props => (props.show ? 'flex' : 'flex')};
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin: 5px;
`;



const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin: 5px;
`;

const BoxContainer = styled.div`
  display: flex;
  align-self:center;
  flex-wrap: wrap;
  height:  ${props => (props.show ? '40vh' : '10vh')};
  width:  ${props => (props.show ? '50vw' : '35vw')};

  overflow:auto;
`;

const Box = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  align-self:center;
`;


const SportNavBar = ({ itemss, setTeamNames , setPlayerNames}) => {
  // const { setTeamNames{} = useContext(PriceContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showTeamdown, setTeamDropdown] = useState(false);
  const { setPlayerName} = useContext(PointsContext);


  const teams = ['ATL', 'BOS', 'BRK', 'CHI', 'CHO', 'CLE', 'DAL', 'DEN', 'DET', 'GSW', 'HOU', 'IND', 'LAC', 'LAL', 'MEM', 'MIL', 'MIN', 'MIA', 'NOP', 'NYK', 'OKC', 'ORL', 'PHI', 'PHO', 'POR', 'SAC', 'SAS', 'TOR', 'UTA', 'WAS']

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClose = () => {
    setShowDropdown(false);
  };


  const handleWiden = () => {
    setTeamDropdown(!showTeamdown);
  };

  async function load_players (x) {
//////////////////////
let team = x
axios({
  method: 'post',
  url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
  withCredentials: false,
  data: {
  team:team,
    path:'team-data'
  },
}).then(function (response) {
  let player_list = []
  let data = response.data

  for (let i = 0; i < data.length; i++) {
    let p = data[i].starters
    player_list.push(p)
  }
  player_list =player_list.sort()
setPlayerNames(player_list)
  

})
.catch(function (error) {
});




   /////////////////// 

  

  }


  const handleTeamClick = (nteamn) => {

    setTeamNames(nteamn)
    load_players(nteamn)
    setShowDropdown(false);
  };

  function handleEmpty(){
  setPlayerNames([])
  }

  return (
    <NavContainer>
      <DropdownButton onClick={handleDropdownToggle}>
        Select Team
      </DropdownButton>
      <DropdownContent show={showDropdown}>
        <CloseButton onClick={handleClose}>x</CloseButton>
        <SelectionList>
          {teams.map((team, index) => (
            <ListItem key={index} onClick={() => handleTeamClick(team)}>
              {team}
            </ListItem>
          ))}
        </SelectionList>
      </DropdownContent>
      <BoxContainer show ={showTeamdown}>
      <OpenButton show={showDropdown} onClick={handleWiden}>x</OpenButton>

        {itemss.map((item, index) => (
          <Box key={index} onClick={() => setPlayerName(item)}>{item}</Box>
        ))}
                <CloseButton onClick={handleEmpty}>x</CloseButton>

      </BoxContainer>
    </NavContainer>
  );
};




export default SportNavBar