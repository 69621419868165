import React, { useState } from 'react';
import styled from 'styled-components';
import { PriceContext } from '../context';
import { useContext } from 'react';

const CounterContainer = styled.div`
  display:flex;
  justify-content:center;
  height:10vh;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  display: block;
  margin: 10px 0;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const CreditCounter = () => {
  const { credit} = useContext(PriceContext);

  return (
    <CounterContainer>
      <h2>Credits: {credit}</h2>
      {/* <Button onClick={() => setCount(count + 1)}>Increment</Button>
      <Button onClick={() => setCount(count - 1)}>Decrement</Button> */}
    </CounterContainer>
  );
};

export default CreditCounter;